import React, { useState } from 'react'
import Layout from '@components/layout/layout'
import Container from '@components/shared/container'
import SEO from '@components/shared/seo'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Masonry from 'react-masonry-component'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'

export default ({ data }) => {
  const project = data.project
  const gatsbyImages = data.images.edges.map(image => {
    return image.node
  })

  // Ajout des images gatsby aux propriétés du projet :
  project.images.map(image => {
    image.gatsby = gatsbyImages.find(el => el.relativePath === image.src)
    return image
  })

  const imagesPaths = project.images.map(image =>
    image.gatsby.childImageSharp.fluid.srcSet.split(',').pop().split(' ')[0].replace(/^[\r\n]+|[\r\n]+$/g, "")
  )

  // SEO
  const makeSeo = project => {
    return {
      "openGraph": {
        "id": project.path.substr(1, project.path.length),
        "title": project.seo.title,
        "description": project.seo.description
      },
      "microdata": {
        "@context": "http://schema.org",
        "@type": "WebPage",
        "name": project.seo.title,
        "mainEntity": {
          "@type": "WebSite",
          "url": project.externalUrl,
          "name": project.title,
          "author": {
            "@type": "Organization",
            "name": "Acolad Développement",
            "sameAs": "https://acolad.fr/contact",
          },
        }
      }
    }
  }

  // Masonry
  const masonryOptions = { fitWidth: true, stagger: 30 }

  // Modal
  const [photoIndex, setPhotoIndex] = useState(0)
  const [isOpen, openModal] = useState(false)

  const openPhoto = i => {
    setPhotoIndex(i)
    openModal(true)
  }

  return (
    <Layout extraClass="page-project">
      <SEO data={makeSeo(project)}/>
      <section className="project page-padding">
        <Container>
          <div className="project">
            {isOpen && (
              <Lightbox
                enableZoom={false}
                mainSrc={imagesPaths[photoIndex]}
                nextSrc={imagesPaths[(photoIndex + 1) % imagesPaths.length]}
                prevSrc={imagesPaths[(photoIndex + imagesPaths.length - 1) % imagesPaths.length]}
                onCloseRequest={() => openModal(false)}
                onMovePrevRequest={() =>
                  setPhotoIndex((photoIndex + imagesPaths.length - 1) % imagesPaths.length)
                }
                onMoveNextRequest={() =>
                  setPhotoIndex((photoIndex + 1) % imagesPaths.length)
                }
              />
            )}
            <div className="project__all">
              <Link to="/projets">Voir tous les projets</Link>
            </div>
            <div className="project__description">
              <h2 className="project__punchline">{project.description}</h2>
              <a className="project__url" target="_blank" href={project.externalUrl}>{project.externalUrl}</a>
              <p className="project__content">{project.content}</p>
            </div>
            <Masonry className="project__galery" options={masonryOptions}>
              {project.images.map((image, index) =>
                <div
                  className="project__galery__item"
                  key={index}
                  onClick={e => openPhoto(index)}
                >
                  <Img fluid={image.gatsby.childImageSharp.fluid} alt={image.alt}/>
                </div>
              )}
            </Masonry>
          </div>
        </Container>
      </section>
    </Layout>
  )
}

export const query = graphql` 
query ($title: String!, $imgs:[String!]) {
  project : projectsJson(title: { eq: $title }) {
    path
    title
    externalUrl
    content
    description
    images {
      alt
      src
    }
    seo {
      title
      description
    }
  }
  images : allFile(filter: {relativePath: {in: $imgs}}) {
    edges {
      node {
        relativePath
        childImageSharp {
          fluid(maxWidth: 450) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
}
`
